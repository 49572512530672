import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "BrokenProductionOrders";
// you might want to manually translate TITLE to Italian
const TITLE = "Incongruenze ordini di produzione";
const COLUMNS = [
  // { title: 'id_run', field: 'id_run', type: 'numeric', editable: 'never' },
  { title: 'Codice articolo', field: 'cod_articolo', type: 'string', editable: 'never' },
  { title: 'Codice variante', field: 'cod_variante', type: 'string', editable: 'never' },
  { title: 'Codice versione', field: 'cod_versione', type: 'numeric', editable: 'never' },
  { title: 'Codice  deposito', field: 'cod_store', type: 'string', editable: 'never' },
  { title: 'Data consegna richiesta', field: 'data_consegna_richiesta', type: 'datetime', editable: 'never' },
  { title: 'Quantità ordinata', field: 'qt_da_prod', type: 'numeric', editable: 'never' },
  { title: 'Numero di registrazione', field: 'cod_numreg', type: 'numeric', editable: 'never' },
  { title: 'Esercizio', field: 'cod_esercizio', type: 'numeric', editable: 'never' },
  { title: 'Numero riga', field: 'cod_num_riga', type: 'numeric', editable: 'never' },
  { title: 'Tipo documento', field: 'cod_tipo_doc', type: 'string', editable: 'never' },
  { title: 'Numero documento', field: 'cod_num_doc', type: 'numeric', editable: 'never' },
  { title: 'Distinta base', field: 'id_db', type: 'numeric', editable: 'never' },
  { title: 'Alternativa di ciclo', field: 'alter_ciclo', type: 'numeric', editable: 'never' },
  { title: 'Descrizione incongruenza', field: 'des_broken', type: 'string', editable: 'never' },
];


const getData = async (runId) => await(getApi(`BrokenProductionOrders/?run_id=${runId}`));

export default function BrokenProductionOrdersTable(props) {
  const [data, setData] = useState([]);
  const { runId } = props;

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getData(runId);
    setData(response.data);
  })(), [runId]);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}
