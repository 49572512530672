import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "TransfersTimes";
// you might want to manually translate TITLE to Italian
const TITLE = "Tempi di Movimentazione";
const COLUMNS = [
  { title: 'Deposito Partenza', field: 'deposito_partenza', type: 'string', editable: 'never' },
  // { title: 'Zona Partenza', field: 'zona_partenza', type: 'string', editable: 'never' },
  { title: 'Indirizzo Zona Partenza', field: 'indirizzo_zona_partenza', type: 'string', editable: 'never' },
  { title: 'Deposito Arrivo', field: 'deposito_arrivo', type: 'string', editable: 'never' },
  // { title: 'Zona Arrivo', field: 'zona_arrivo', type: 'string', editable: 'never' },
  { title: 'Indirizzo Zona Arrivo', field: 'indirizzo_zona_arrivo', type: 'string', editable: 'never' },
  { title: 'Ore Movimentazione (Ore) (0.5=30min)', field: 'time_transfer', type: 'numeric', editable: 'always' },
  { title: 'Ritardo Uscita (Ore) (0.5=30min)', field: 'delay_uscita', type: 'numeric', editable: 'always' },
  { title: 'Ritardo Entrata (Ore) (0.5=30min)', field: 'delay_entrata', type: 'numeric', editable: 'always' }
];

const ID_COLUMN = "id"

const getData = async () => await(getApi(`TransfersTimes/`));
const updateData = async (record) => await patchApi(`TransfersTimes/${record.id}/`, record);
const deleteData = async (record) => await deleteApi(`TransfersTimes/${record.id}/`, record);

export default function TransfersTimesTable(props) {
  const [data, setData] = useState([]);
  
  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === oldData[ID_COLUMN]) {
        d.flg_disabled = !d.flg_disabled;
      }

      return d;
    }))
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}
