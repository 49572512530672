import React, { useState } from "react";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../components/TabPanel/index.jsx";
import useStyles from "./styles.js";

import { useAbility } from '@casl/react';
import { AbilityContext } from '../../utils/authentication';

import BrokenBomsTable from "../../components/Tables/Entities/BrokenBoms.jsx";
import BrokenCustomerOrdersTable from "../../components/Tables/Entities/BrokenCustomerOrders.jsx";
import BrokenIncomingItemsTable from "../../components/Tables/Entities/BrokenIncomingItems.jsx";
import BrokenItemsTable from "../../components/Tables/Entities/BrokenItems.jsx";
import BrokenLiveOrdersTable from "../../components/Tables/Entities/BrokenLiveOrders.jsx";
import BrokenProductionOrdersTable from "../../components/Tables/Entities/BrokenProductionOrders.jsx";
import BrokenProductionOrdersMaterialsTable from "../../components/Tables/Entities/BrokenProductionOrdersMaterials.jsx";
import BrokenStageMaterialsTable from "../../components/Tables/Entities/BrokenStageMaterials.jsx";
import BrokenStagesTable from "../../components/Tables/Entities/BrokenStages.jsx";
import BrokenStockTable from "../../components/Tables/Entities/BrokenStock.jsx";

export default function DataEntry(props) {
  const classes = useStyles();
  const [ currentPage, setCurrentPage ] = useState(0);
  const ability = useAbility(AbilityContext);
  const { runId } = props.match.params;

  const TABS = [
    {
      title: 'Incongruenze distinte base',
      entity: 'BrokenBoms',
      component: <BrokenBomsTable runId={runId} />
    },
    {
      title: 'Incongruenze delle fasi',
      entity: 'BrokenStages',
      component: <BrokenStagesTable runId={runId} />
    },
    {
      title: 'Incongruenze anagrafica articoli',
      entity: 'BrokenItems',
      component: <BrokenItemsTable runId={runId} />
    },
    {
      title: 'Incongruenze ordini in corso',
      entity: 'BrokenLiveOrders',
      component: <BrokenLiveOrdersTable runId={runId} />
    },
    {
      title: 'Incongruenze ordini di produzione',
      entity: 'BrokenProductionOrders',
      component: <BrokenProductionOrdersTable runId={runId} />
    },
    {
      title: 'Incongruenze ordini cliente',
      entity: 'BrokenCustomerOrders',
      component: <BrokenCustomerOrdersTable runId={runId} />
    },
    {
      title: 'Incongruenze materiali ordini di produzione',
      entity: 'BrokenProductionOrdersMaterials',
      component: <BrokenProductionOrdersMaterialsTable runId={runId} />
    },
    {
      title: 'Incongruenze materiali fasi',
      entity: 'BrokenStageMaterials',
      component: <BrokenStageMaterialsTable runId={runId} />
    },
    {
      title: 'Incongruenze articoli entranti',
      entity: 'BrokenIncomingItems',
      component: <BrokenIncomingItemsTable runId={runId} />
    },
    {
      title: 'Incongruenze giacenza',
      entity: 'BrokenStock',
      component: <BrokenStockTable runId={runId} />
    }
  ];

  const handleChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
      spacing={2}
    >
      <AppBar 
        position="static"
      >
        <Tabs
          value={currentPage}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {TABS.filter(t => ability.can('read', t.entity)).map((t, i) => (
            <Tab label={t.title} key={i} />
          ))}
        </Tabs>
      </AppBar>
      {TABS.filter(t => ability.can('read', t.entity)).map((t, i) => (
        <TabPanel value={currentPage} index={i} key={i}>
          {t.component}
        </TabPanel>
      ))}
    </Grid>
  );
}
