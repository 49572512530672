import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi, postApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "MachinesFailures";
// you might want to manually translate TITLE to Italian
const TITLE = "Guasti";
const COLUMNS = [
  { title: 'Id Richiesta', field: 'id_richiesta', type: 'numeric', editable: 'always' },
  { title: 'Codice Macchina', field: 'cod_machine', type: 'string', editable: 'always' },
  { title: 'Descrizione Guasto', field: 'des_failure', type: 'string', editable: 'always' },
  { title: 'Data Inizio Riparazione', field: 'dt_start_repair', type: 'datetime', editable: 'always' },
  { title: 'Minuti Riparazione', field: 'min_repair', type: 'numeric', editable: 'always' },
];

const ID_COLUMN = "id";

const getData = async () => await(getApi(`MachinesFailures/`));
const createData = async (record) => await postApi(`MachinesFailures/`, record);
const updateData = async (record) => await patchApi(`MachinesFailures/${record.id}`, record);
const deleteData = async (record) => await deleteApi(`MachinesFailures/${record.id}`, record);

export default function MachinesFailuresTable(props) {
  const [data, setData] = useState([]);
  
  const onRowAdd = async (newData) => {

    try {
      newData = await createData(newData);
      setData([ ...data, newData ]);
    } catch (err) {
      console.error(err);
    }
  }

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.filter(d => d[ID_COLUMN] !== oldData[ID_COLUMN]));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowAdd,
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}
