import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi, postApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "SimulatedOrders";
// you might want to manually translate TITLE to Italian
const TITLE = "Ordini Simulati";
const COLUMNS = [
  { title: 'Codice Cliente', field: 'cod_customer', type: 'numeric', editable: 'always' },
  { title: 'Codice Spedizione Cliente', field: 'cod_customer_shipping', type: 'string', editable: 'always' },
  { title: 'Codice Indirizzo', field: 'cod_address', type: 'string', editable: 'always' },
  { title: 'Codice Articolo', field: 'cod_article', type: 'string', editable: 'always' },
  { title: 'Codice Variante', field: 'cod_variant', type: 'numeric', editable: 'always' },
  { title: 'Codice Versione', field: 'cod_version', type: 'numeric', editable: 'always' },
  { title: 'Quantità', field: 'val_quantity', type: 'numeric', editable: 'always' },
  { title: 'Data Spedizione', field: 'dt_shipment', type: 'date', editable: 'always' },
  { title: 'Data Creazione', field: 'dt_creation', type: 'date', editable: 'always' },
  { title: 'Prezzo', field: 'val_price', type: 'numeric', editable: 'always' },
  { title: 'Utente', field: 'id_user', type: 'numeric', editable: 'never' },
  { title: 'Codice Deposito', field: 'cod_store', type: 'string', editable: 'never' }
];

const ID_COLUMN = "id"

const getData = async () => await(getApi(`SimulatedOrders/`));
const createData = async (record) => await postApi(`SimulatedOrders/`, record);
const updateData = async (record) => await patchApi(`SimulatedOrders/${record.id}/`, record);
const deleteData = async (record) => await deleteApi(`SimulatedOrders/${record.id}/`, record);

export default function SimulatedOrdersTable(props) {
  const [data, setData] = useState([]);
  
  const onRowAdd = async (newData) => {

    try {
      newData = await createData(newData);
      setData([ ...data, newData ]);
    } catch (err) {
      console.error(err);
    }
  }

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.filter(d => d[ID_COLUMN] !== oldData[ID_COLUMN]));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowAdd,
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}
