import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "BrokenStock";
// you might want to manually translate TITLE to Italian
const TITLE = "Incongruenze giacenza";
const COLUMNS = [
  // { title: 'id_run', field: 'id_run', type: 'numeric', editable: 'never' },
  { title: 'Codice articolo', field: 'cod_articolo', type: 'string', editable: 'never' },
  { title: 'Codice variante', field: 'cod_variante', type: 'string', editable: 'never' },
  { title: 'Codice versione', field: 'cod_versione', type: 'numeric', editable: 'never' },
  { title: 'Giacenza', field: 'giacenza', type: 'numeric', editable: 'never' },
  { title: 'Unità di misura', field: 'um', type: 'string', editable: 'never' },
  { title: 'Codice deposito', field: 'cod_deposito', type: 'string', editable: 'never' },
  { title: 'Zona deposito', field: 'zona', type: 'string', editable: 'never' },
  { title: 'Descrizione incongruenza', field: 'des_broken', type: 'string', editable: 'never' },
];


const getData = async (runId) => await(getApi(`BrokenStock/?run_id=${runId}`));

export default function BrokenStockTable(props) {
  const [data, setData] = useState([]);
  const { runId } = props;

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getData(runId);
    setData(response.data);
  })(), [runId]);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}
