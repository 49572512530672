import React from "react";
import DemandsTable from "../../components/Tables/Entities/Demands";

export default function RunDemandTable(props) {
  const { runId } = props.match.params;

  return (
    <DemandsTable runId={runId} />
  );
}
