import React from "react";
import CanTable from "../CanTable";

const ENTITY = "run";
const COLUMNS = [
  { title: 'Run', field: 'id_run', type: 'numeric'},
  { title: 'Codice Produzione', field: 'cod_production', type: 'string'},
  { title: 'Inizio', field: 'tms_start', type: 'datetime'},
  { title: 'Fine', field: 'tms_end', type: 'datetime'},
  { title: 'Codice Macchina', field: 'cod_machine', type: 'string'},
  { title: 'Tipo', field: 'cod_type_interval', type: 'string'},
  { title: 'Codice Cliente', field: 'cod_customer', type: 'string'},
  { title: 'Data Scadenza', field: 'dat_deadline', type: 'date'},
  { title: 'Tassativo', field: 'flg_forcing', type: 'bool'},
  { title: 'Codice Articolo', field: 'list_cod_article', type: 'string'},
  { title: 'Codice Numero Documento', field: 'list_cod_num_doc', type: 'string'},
  { title: 'Quantità totale', field: 'list_qta_totale', type: 'string'},
  { title: 'Quantità residua', field: 'list_qta_residua', type: 'string'},
  { title: 'Quantità scarti', field: 'list_qta_scarti', type: 'string'},
  { title: 'Tempo singolo pezzo', field: 'tempo_singolo_pezzo', type: 'string'},
  { title: 'UM', field: 'um', type: 'string'},
  { title: 'Ultima fase produzione', field: 'ultima_fase_prod', type: 'string'},
];

export default function GanttBlocksTable(props) {
  return (
    <CanTable
      title={props.title}
      entity={ENTITY}
      columns={COLUMNS}
      data={props.data}
      actions={[{
        icon: "launch",
        tooltip: "Seleziona",
        onClick: (event, rowData) => props.onSelect(rowData)
      }]}
    />
  );
}