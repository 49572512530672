import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "Demands";
// you might want to manually translate TITLE to Italian
const TITLE = "Domanda";
const COLUMNS = [
  // { title: 'id_run', field: 'id_run', type: 'numeric', editable: 'never' },
  { title: 'Codice articolo', field: 'cod_articolo', type: 'string', editable: 'never' },
  { title: 'Codice variante', field: 'cod_variante', type: 'string', editable: 'never' },
  { title: 'Codice versione', field: 'cod_versione', type: 'numeric', editable: 'never' },
  { title: 'Data richiesta materiale', field: 'data_richiesta_materiale', type: 'datetime', editable: 'never' },
  { title: 'Quantità fabbisogno', field: 'qt_fabbisogno', type: 'numeric', editable: 'never' },
  { title: 'Quantità giacenza', field: 'val_quantity_stock', type: 'numeric', editable: 'never' },
  { title: 'Quantità in ordine', field: 'val_quantity_replenishment', type: 'numeric', editable: 'never' },
  { title: 'Quantità da ordinare', field: 'val_quantity_to_order', type: 'numeric', editable: 'never' },
  { title: 'Lista Ordini', field: 'list_orders', type: 'string', editable: 'never' },
];

const getData = async (runId) => await(getApi(`Demands/?run_id=${runId}`));

export default function DemandsTable(props) {
  const [data, setData] = useState([]);
  const { runId } = props;

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getData(runId);
    setData(
      response.data
        .map(d => ({
          ...d,
          val_quantity_to_order: d.qt_fabbisogno - d.val_quantity_replenishment - d.val_quantity_stock,
          list_orders: d.list_orders.join(',')
        }))
        .filter(d => d.val_quantity_to_order > 0)
    );
  })(), [runId]);

  console.log(data)

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}
