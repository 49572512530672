import React, { useState } from "react";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../components/TabPanel/index.jsx";
import useStyles from "./styles.js";

import { useAbility } from '@casl/react';
import { AbilityContext } from '../../utils/authentication';

import BasesStopsTable from "../../components/Tables/Entities/BasesStops.jsx";
import SimulatedOrdersTable from "../../components/Tables/Entities/SimulatedOrders.jsx";
import ForecastOrdersTable from "../../components/Tables/Entities/ForecastOrders.jsx";
import MandatoryOrdersTable from "../../components/Tables/Entities/MandatoryProductionOrders.jsx";
import MaterialsShipmentsTable from "../../components/Tables/Entities/MaterialsShipments.jsx";
import TransfersFrequenciesTable from "../../components/Tables/Entities/TransfersFrequencies.jsx";
import TransfersTimesTable from "../../components/Tables/Entities/TransfersTimes.jsx";
import MachinesFailuresTable from "../../components/Tables/Entities/MachinesFailures.jsx";
import OperatorsTravelsTable from "../../components/Tables/Entities/operators_travels.jsx";

export default function DataEntry(props) {
  const classes = useStyles();
  const [ currentPage, setCurrentPage ] = useState(0);
  const ability = useAbility(AbilityContext);

  const TABS = [
    // {
    //   title: 'Fermi macchina',
    //   entity: 'machines_stop',
    //   component: <MachinesStopsTable />
    // },
    // {
    //   title: 'Anagrafica operatori',
    //   entity: 'operators_registry',
    //   component: <OperatorsRegistryTable />
    // },
    // {
    //   title: 'Ordini di lavoro',
    //   entity: 'working_order',
    //   component: <WorkingOrdersTable />
    // },
    {
      title: 'Ordini simulati',
      entity: 'SimulatedOrders',
      component: <SimulatedOrdersTable />
    },
    {
      title: 'Ordini previsionali',
      entity: 'ForecastOrders',
      component: <ForecastOrdersTable />
    },
    {
      title: 'Frequenze di movimentazione',
      entity: 'TransfersFrequencies',
      component: <TransfersFrequenciesTable />
    },
    {
      title: 'Tempi di movimentazione',
      entity: 'TransfersTimes',
      component: <TransfersTimesTable />
    },
    {
      title: 'Ordini Tassativi',
      entity: 'MandatoryOrders',
      component: <MandatoryOrdersTable />
    },
    {
      title: 'Spedizioni Ordini Conto Lavoro/Arrivo Merce',
      entity: 'MaterialsShipments',
      component: <MaterialsShipmentsTable />
    },
    {
      title: 'Guasti',
      entity: 'MachinesFailures',
      component: <MachinesFailuresTable />
    },
    {
      title: 'Fermi per basette',
      entity: 'BasesStops',
      component: <BasesStopsTable />
    },
    {
      title: 'Trasferte operatori',
      entity: 'operators_travels',
      component: <OperatorsTravelsTable />
    },
  ];

  const handleChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
      spacing={2}
    >
      <AppBar 
        position="static"
      >
        <Tabs
          value={currentPage}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {TABS.filter(t => ability.can('read', t.entity)).map((t, i) => (
            <Tab label={t.title} key={i} />
          ))}
        </Tabs>
      </AppBar>
      {TABS.filter(t => ability.can('read', t.entity)).map((t, i) => (
        <TabPanel value={currentPage} index={i} key={i}>
          {t.component}
        </TabPanel>
      ))}
    </Grid>
  );
}
