import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "BasesStops";
// you might want to manually translate TITLE to Italian
const TITLE = "Fermi per basette";
const COLUMNS = [
  { title: 'Id Riga', field: 'id_riga', type: 'numeric', editable: 'never' },
  { title: 'Ora inizio', field: 'time_start', type: 'time', editable: 'never' },
  { title: 'Ora fine', field: 'time_end', type: 'time', editable: 'never' },
  { title: 'Lunedì', field: 'lunedi', type: 'boolean', editable: 'always' },
  { title: 'Martedì', field: 'martedi', type: 'boolean', editable: 'always' },
  { title: 'Mercoledì', field: 'mercoledi', type: 'boolean', editable: 'always' },
  { title: 'Giovedì', field: 'giovedi', type: 'boolean', editable: 'always' },
  { title: 'Venerdì', field: 'venerdi', type: 'boolean', editable: 'always' },
  { title: 'Sabato', field: 'sabato', type: 'boolean', editable: 'always' },
  { title: 'Domenica', field: 'domenica', type: 'boolean', editable: 'always' }
];

const ID_COLUMN = "id_riga"

const getData = async () => await(getApi(`BasesStops/`));
const updateData = async (record) => await patchApi(`BasesStops/${record.id}/`, record);
const deleteData = async (record) => await deleteApi(`BasesStops/${record.id}/`, record);

export default function BasesStopsTable(props) {
  const [data, setData] = useState([]);
  
  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === oldData[ID_COLUMN]) {
        d.flg_disabled = !d.flg_disabled;
      }

      return d;
    }))
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}
