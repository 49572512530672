import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "CustomerOrders";
// you might want to manually translate TITLE to Italian
const TITLE = "Portafoglio Ordini";
const COLUMNS = [
  { title: 'Codice Articolo', field: 'cod_articolo', type: 'string', editable: 'never' },
  { title: 'Codice Variante', field: 'cod_variante', type: 'string', editable: 'never' },
  { title: 'Codice Versione', field: 'cod_versione', type: 'numeric', editable: 'never' },
  { title: 'Codice Cliente', field: 'cod_customer', type: 'string', editable: 'never' },
  { title: 'Ragione Sociale', field: 'rag_sociale', type: 'string', editable: 'never' },
  { title: 'Codice Deposito', field: 'cod_deposito', type: 'string', editable: 'never' },
  { title: 'Quantità Ordinata', field: 'qta_ordinata', type: 'numeric', editable: 'never' },
  { title: 'Quantità Consegnata', field: 'qta_consegnata', type: 'numeric', editable: 'never' },
  { title: 'Data Consegna Cliente', field: 'data_cons_cliente', type: 'date', editable: 'never' },
  { title: 'UM', field: 'um', type: 'string', editable: 'never' },
  { title: 'Codice Numero Registrazione', field: 'cod_numreg', type: 'string', editable: 'never' },
  { title: 'Codice Esercizio', field: 'cod_esercizio', type: 'numeric', editable: 'never' },
  { title: 'Codice Numero Riga', field: 'cod_num_riga', type: 'numeric', editable: 'never' },
  { title: 'Codice Tipo Documento', field: 'cod_tipo_doc', type: 'string', editable: 'never' },
  { title: 'Codice Numero Documento', field: 'cod_num_doc', type: 'int', editable: 'never' },
  { title: 'Stato', field: 'stato', type: 'string', editable: 'never' }
];

const getData = async () => await(getApi(`CustomerOrders/`));

export default function CustomerOrdersTable(props) {
  const [data, setData] = useState([]);

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}
