import React from "react";
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography } from '@material-ui/core';

import { useAbility } from '@casl/react';
import { AbilityContext, Can } from '../../../utils/authentication';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { styled } from "@material-ui/styles";

import DESCRIPTIONS from '../../../assets/table_descriptions.json';


const exportLocalCsv = (cols, datas, filename, seprator) => {
  const local_datas = datas.map((row) => {
    return row.map(item => {
      if (item === null || item === undefined) 
        return item; 
      return item.toLocaleString('it-IT');
    })
  })
  ExportCsv(cols, local_datas, filename, seprator)
}

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));


function CanTable(props) {
  const { I, entity, editable, options, columns, title, ...rest} = props;
  const ability = useAbility(AbilityContext);

  return (
    <Can I="read" a={entity}>
      <MaterialTable
        title={ DESCRIPTIONS?.[entity]?._description ? (
            <Accordion>
              <StyledAccordionSummary id={`${entity}-header`}>
                <Typography variant="h6">{title}</Typography>
              </StyledAccordionSummary>
              <AccordionDetails style={{display: 'block'}}>
                <pre style={{ fontFamily: 'Monospace', fontSize: '13px', whiteSpace: "pre-wrap" }}>
                  <Typography >
                    {DESCRIPTIONS[entity]._description}
                  </Typography>
                </pre>
              </AccordionDetails>
            </Accordion>
          ) : title
        }
        columns={columns.map(c => {
          const description = DESCRIPTIONS?.[entity]?.[c.field];

          return {
            ...c,
            title: description ?
              (
                <Tooltip title={description}>
                  <span>{c.title}</span>
                </Tooltip>
              ) :
              c.title
          }
        })}
        editable={{
          ...editable,
          onRowAdd: ability.can('create', entity) ? editable?.onRowAdd : null,
          onRowUpdate: ability.can('update', entity) ? editable?.onRowUpdate : null,
          onRowDelete: ability.can('delete', entity) ? editable?.onRowDelete : null
        }}
        options={{
          ...options,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          exportMenu: [{
            label: 'Export PDF',
            exportFunc: (cols, datas) => ExportPdf(cols, datas, `${props.title} export`)
          }, {
            label: 'Export CSV',
            exportFunc: (cols, datas) => exportLocalCsv(cols.map(c => ({ ...c, title: c.field })), datas, `${props.title} export`, ';')
          }],
          exportAllData: true,
          rowStyle: rowData => ({
            ...options?.rowStyle,
            backgroundColor: rowData.flg_disabled ? '#B0B0B0' : '#FFFFFF'
          })
        }}
        localization={{
          header: {
            actions: 'Azioni'
          }
        }}
        {...rest}
      />
    </Can>
  );
}

export default CanTable;