import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "TransfersFrequencies";
// you might want to manually translate TITLE to Italian
const TITLE = "Frequenze di Movimentazione";
const COLUMNS = [
  { title: 'Deposito Partenza', field: 'deposito_partenza', type: 'string', editable: 'never' },
  // { title: 'Zona Partenza', field: 'zona_partenza', type: 'string', editable: 'never' },
  { title: 'Indirizzo Zona Partenza', field: 'indirizzo_zona_partenza', type: 'string', editable: 'never' },
  { title: 'Deposito Arrivo', field: 'deposito_arrivo', type: 'string', editable: 'never' },
  // { title: 'Zona Arrivo', field: 'zona_arrivo', type: 'string', editable: 'never' },
  { title: 'Indirizzo Zona Arrivo', field: 'indirizzo_zona_arrivo', type: 'string', editable: 'never' },
  { title: 'Id Movimentazione', field: 'id_transfer', type: 'numeric', editable: 'never' },
  { title: 'Giorno Movimentazione', field: 'day_transfer', type: 'string', editable: 'never' },
  { title: 'Ora Movimentazione', field: 'hour_transfer', type: 'time', editable: 'always' }
];

const ID_COLUMN = "id"

const getData = async () => await(getApi(`TransfersFrequencies/`));
const updateData = async (record) => await patchApi(`TransfersFrequencies/${record.id}/`, record);
const deleteData = async (record) => await deleteApi(`TransfersFrequencies/${record.id}/`, record);

export default function TransfersFrequenciesTable(props) {
  const [data, setData] = useState([]);
  
  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === oldData[ID_COLUMN]) {
        d.flg_disabled = !d.flg_disabled;
      }

      return d;
    }))
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}
