import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { deleteApi, getApi, patchApi, postApi, postFileApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "ForecastOrders";
// you might want to manually translate TITLE to Italian
const TITLE = "Ordini Previsionali";
const COLUMNS = [
  { title: 'Codice Ordine', field: 'id_testata', type: 'numeric', editable: 'onAdd' },
  { title: 'Riga Ordine', field: 'id_ordine', type: 'numeric', editable: 'onAdd' },
  { title: 'Codice Cliente', field: 'cod_customer', type: 'numeric', editable: 'always' },
  { title: 'Codice Spedizione Cliente', field: 'cod_customer_shipping', type: 'string', editable: 'always' },
  { title: 'Codice Indirizzo', field: 'cod_address', type: 'string', editable: 'always' },
  { title: 'Codice Articolo', field: 'cod_article', type: 'string', editable: 'always' },
  { title: 'Codice Variante', field: 'cod_variant', type: 'string', editable: 'always' },
  { title: 'Codice Versione', field: 'cod_version', type: 'numeric', editable: 'always' },
  { title: 'Quantità', field: 'val_quantity', type: 'numeric', editable: 'always' },
  { title: 'Data Spedizione', field: 'dt_shipment', type: 'date', editable: 'always' },
  { title: 'Data Creazione', field: 'dt_creation', type: 'date', editable: 'always' },
  { title: 'Prezzo', field: 'val_price', type: 'numeric', editable: 'always' },
  { title: 'Codice Deposito', field: 'cod_store', type: 'string', editable: 'always' },
  { title: 'Utente', field: 'id_user', type: 'numeric', editable: 'never' }
];

const ID_COLUMN = "id"

const getData = async () => await(getApi(`ForecastOrders/`));
const createData = async (record) => await postApi(`ForecastOrders/`, record);
const updateData = async (record) => await patchApi(`ForecastOrders/${record.id}/`, record);
const deleteData = async (record) => await deleteApi(`ForecastOrders/${record.id}/`, record);
const createBulkData = async (file) => await postFileApi(`ForecastOrders/bulk/`, file);

export default function ForecastOrdersTable(props) {
  const [data, setData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  
  const onRowAdd = async (newData) => {

    try {
      newData = await createData(newData);
      setData([ ...data, newData ]);
    } catch (err) {
      console.error(err);
    }
  }

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.filter(d => d[ID_COLUMN] !== oldData[ID_COLUMN]));
  }

  const handleFileChange = ({ target }) => {
    setUploadFile(target.files[0]);
  };

  const toggleDialogOpen = () => {
    setDialogOpen(!dialogOpen);
  }

  const onFileUpload = async () => {
    const result = await createBulkData(uploadFile);
    setData(data.concat(result.data));
    toggleDialogOpen();
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <>
      <CanTable
        title={TITLE}
        entity={ENTITY}
        columns={COLUMNS}
        data={data}
        editable={{
          onRowAdd,
          onRowUpdate,
          onRowDelete,
        }}
        actions={[{
          icon: 'file_upload',
          tooltip: 'Upload',
          isFreeAction: true,
          onClick: toggleDialogOpen 
        }]}
      />
      <Dialog open={dialogOpen} onClose={toggleDialogOpen}>
        <DialogTitle>Import Ordini Previsionali</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selezionare un file csv avente lo stesso formato di quello esportato
          </DialogContentText>
          <>
            <input
              accept="csv"
              id="faceImage"
              type="file"
              onChange={handleFileChange}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialogOpen}>Annulla</Button>
          <Button onClick={onFileUpload}>Salva</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
