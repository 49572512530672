import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "BrokenBoms";
// you might want to manually translate TITLE to Italian
const TITLE = "Incongruenze distinte base";
const COLUMNS = [
  // { title: 'id_run', field: 'id_run', type: 'numeric', editable: 'never' },
  { title: 'Distinta base padre', field: 'db_padre', type: 'numeric', editable: 'never' },
  { title: 'Codice articolo padre', field: 'cod_articolo_padre', type: 'string', editable: 'never' },
  { title: 'Codice variante padre', field: 'cod_variante_padre', type: 'string', editable: 'never' },
  { title: 'Codice versione padre', field: 'cod_versione_padre', type: 'numeric', editable: 'never' },
  { title: 'Distinta base figlio', field: 'db_figlio', type: 'numeric', editable: 'never' },
  { title: 'Codice articolo figlio', field: 'cod_articolo_figlio', type: 'string', editable: 'never' },
  { title: 'Codice variante figlio', field: 'cod_variante_figlio', type: 'string', editable: 'never' },
  { title: 'Codice versione figlio', field: 'cod_versione_figlio', type: 'numeric', editable: 'never' },
  { title: 'Descrizione incongruenza', field: 'des_broken', type: 'string', editable: 'never' },
  { title: 'Numero di registrazione', field: 'cod_numreg', type: 'numeric', editable: 'never' },
  { title: 'Numero documento', field: 'cod_num_doc', type: 'numeric', editable: 'never' },
  { title: 'Esercizio', field: 'cod_esercizio', type: 'numeric', editable: 'never' },
  { title: 'Tipo ordine', field: 'order_type', type: 'string', editable: 'never' },
];


const getData = async (runId) => await(getApi(`BrokenBoms/?run_id=${runId}`));

export default function BrokenBomsTable(props) {
  const [data, setData] = useState([]);
  const { runId } = props;

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getData(runId);
    setData(response.data);
  })(), [runId]);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}
