import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "BrokenLiveOrders";
// you might want to manually translate TITLE to Italian
const TITLE = "Incongruenze ordini in corso";
const COLUMNS = [
  // { title: 'id_run', field: 'id_run', type: 'numeric', editable: 'never' },
  { title: 'Codice articolo', field: 'cod_articolo', type: 'string', editable: 'never' },
  { title: 'Codice variante', field: 'cod_variante', type: 'string', editable: 'never' },
  { title: 'Codice versione', field: 'cod_versione', type: 'numeric', editable: 'never' },
  { title: 'Numero registrazione', field: 'cod_numreg', type: 'numeric', editable: 'never' },
  { title: 'Esercizio', field: 'cod_esercizio', type: 'numeric', editable: 'never' },
  { title: 'Numero riga', field: 'cod_num_riga', type: 'numeric', editable: 'never' },
  { title: 'Numero documento', field: 'cod_num_doc', type: 'numeric', editable: 'never' },
  { title: 'Rilascio', field: 'id_rilascio', type: 'numeric', editable: 'never' },
  { title: 'Distinta base', field: 'id_db', type: 'numeric', editable: 'never' },
  { title: 'Altrernativa di ciclo', field: 'alter_ciclo', type: 'numeric', editable: 'never' },
  { title: 'Numero operazione', field: 'num_oper', type: 'numeric', editable: 'never' },
  { title: 'Codice macchina', field: 'cod_macchina', type: 'string', editable: 'never' },
  { title: 'Codice mes', field: 'cod_mes', type: 'string', editable: 'never' },
  { title: 'Setup', field: 'flg_setup', type: 'boolean', editable: 'never' },
  { title: 'Descrizione incongruenza', field: 'des_broken', type: 'string', editable: 'never' },
];


const getData = async (runId) => await(getApi(`BrokenLiveOrders/?run_id=${runId}`));

export default function BrokenLiveOrdersTable(props) {
  const [data, setData] = useState([]);
  const { runId } = props;

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getData(runId);
    setData(response.data);
  })(), [runId]);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}
