import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "MaterialsShipments";
// you might want to manually translate TITLE to Italian
const TITLE = "Ordini Conto Lavoro/Arrivo Merce";
const COLUMNS = [
  { title: 'Codice Articolo', field: 'cod_articolo', type: 'string', editable: 'never' },
  { title: 'Codice Esercizio', field: 'cod_esercizio', type: 'numeric', editable: 'never' },
  { title: 'Numero Documento', field: 'cod_num_doc', type: 'numeric', editable: 'never' },
  { title: 'Codice Numero Riga', field: 'cod_num_riga', type: 'numeric', editable: 'never' },
  { title: 'Data Spedizione', field: 'dt_shipment', type: 'date', editable: 'always' }
];

const ID_COLUMN = "id"

const getData = async () => await(getApi(`MaterialsShipments/`));
const updateData = async (record) => await patchApi(`MaterialsShipments/${record.id}/`, record);
const deleteData = async (record) => await deleteApi(`MaterialsShipments/${record.id}/`, record);

export default function MaterialsShipmentsTable(props) {
  const [data, setData] = useState([]);

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.filter(d => d[ID_COLUMN] !== oldData[ID_COLUMN]));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}
