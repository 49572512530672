import React, { useEffect, useState } from "react";
import {getApi, patchApi } from "../../../utils/api";
import CanTable from "../CanTable";

const ENTITY = "operators_travels";
const ENDPOINT = "OperatorsTravels";
const TITLE = "Trasferte operatori";
const COLUMNS = [
  { title: 'Codice articolo', field: 'cod_articolo', type: 'string', editable: 'never'},
  { title: 'Codice variante', field: 'cod_variante', type: 'numeric', editable: 'never'},
  { title: 'Codice versione', field: 'cod_versione', type: 'numeric', editable: 'never'},
  { title: 'Numero di registrazione', field: 'cod_numreg', type: 'numeric', editable: 'never'},
  { title: 'Codice esercizio', field: 'cod_esercizio', type: 'numeric', editable: 'never'},
  { title: 'Codice Numero Riga', field: 'cod_num_riga', type: 'numeric', editable: 'never'},
  { title: 'Numero documento', field: 'cod_num_doc', type: 'numeric', editable: 'never'},
  { title: 'Distinta base', field: 'id_db', type: 'numeric', editable: 'never'},
  { title: 'Alternativa ciclo', field: 'alter_ciclo', type: 'numeric', editable: 'never'},
  { title: 'Numero operazione', field: 'num_oper', type: 'numeric', editable: 'never'},
  { title: 'Matricola', field: 'matricola', type: 'string', editable: 'always'},
  { title: 'Ora inizio', field: 'tms_inizio', type: 'datetime', editable: 'always'},
  { title: 'Ora fine', field: 'tms_fine', type: 'datetime', editable: 'always'},
];
const ID_COLUMN = "id";

const getData = async () => await(getApi(`${ENDPOINT}_list/`));
const updateData = async (record) => await patchApi(`${ENDPOINT}/${record.id}/`, record);

export default function OperatorsTravelsTable(props) {
  const [data, setData] = useState([]);

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{ onRowUpdate }}
    />
  );
}