import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "BrokenProductionOrdersMaterials";
// you might want to manually translate TITLE to Italian
const TITLE = "Incongruenze materiali ordini di produzione";
const COLUMNS = [
  // { title: 'id_run', field: 'id_run', type: 'numeric', editable: 'never' },
  { title: 'Riga', field: 'id_riga', type: 'numeric', editable: 'never' },
  { title: 'Rilascio', field: 'id_rilascio', type: 'numeric', editable: 'never' },
  { title: 'Distinta base', field: 'id_db', type: 'numeric', editable: 'never' },
  { title: 'Alternativa di ciclo', field: 'alter_ciclo', type: 'numeric', editable: 'never' },
  { title: 'Numero operazione', field: 'num_oper', type: 'numeric', editable: 'never' },
  { title: 'Codice materiale', field: 'cod_materiale', type: 'string', editable: 'never' },
  { title: 'Codice variante', field: 'cod_variante', type: 'string', editable: 'never' },
  { title: 'Codice versione', field: 'cod_versione', type: 'numeric', editable: 'never' },
  { title: 'Descrizione incongruenza', field: 'des_broken', type: 'string', editable: 'never' },
];


const getData = async (runId) => await(getApi(`BrokenProductionOrdersMaterials/?run_id=${runId}`));

export default function BrokenProductionOrdersMaterialsTable(props) {
  const [data, setData] = useState([]);
  const { runId } = props;

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getData(runId);
    setData(response.data);
  })(), [runId]);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}
